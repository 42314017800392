const woopraHandler = ({ type, obj = {}, email = false }) => {
  if (email) {
    window.woopra.identify({
      email,
    });
  }

  window.woopra.track(type, obj);
};

module.exports = woopraHandler;

// on button click
// on file upload and amount
// on delete
