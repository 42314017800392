import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import QUERY from "../mediaQueries";
import woopraHandler from "../woopra";
import CryptoJS from "crypto-js";

const UploadButtonStyle = styled.form`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 0.7rem;
  opacity: ${(props) => props.opacity};
  #file-label {
    align-items: center;
    background: #293748;
    border-radius: 5.7px;
    box-shadow: 0px 2.5321099758148193px 2.5321099758148193px 0px #0000001a;
    color: #f5f7fb;
    color: #eff9ff;
    display: grid;
    font-family: Poppins;
    font-size: 20.19px;
    font-style: normal;
    /* padding: 0rem 2rem; */
    font-weight: 600;
    gap: 22px;
    grid-auto-flow: column;
    height: 62px;
    justify-content: center;
    letter-spacing: 1.615px;
    line-height: normal;
    position: relative;
    text-shadow: 0px 2.537px 2.537px rgba(0, 0, 0, 0.1);
    img {
      max-width: 40px;
    }

    @media ${QUERY.desktop} {
      width: 100%;

      white-space: nowrap;
      /* padding: 0 2.7rem; */
      gap: 1.8rem;
      font-size: 24.83px;
      height: 79px;
      img {
        max-width: 51px;
      }
    }
  }
  #file {
    cursor: ${(props) => (props.opacity === 0.5 ? "not-allowed" : "pointer")};
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  #file-button {
    background: #f8c35a;
    border: 0;
    cursor: ${(props) => (props.opacity === 0.5 ? "not-allowed" : "pointer")};
    display: none;
    padding: 1rem;
  }
`;

function computeHmacsha256(data, key) {
  const hmac = CryptoJS.HmacSHA256(data, key);
  return hmac.toString(CryptoJS.enc.Hex);
}

function UploadButton({
  className = "upload-button",
  setFilesStatus,
  setLoading,
  loading,
  files,
  setFile,
  count,
  setCount,
  uploading,
  setUploading,
  setPermFiles,
  selectedMode,
}) {
  const [resFiles, setResFiles] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadMultiFiles();
  };

  const submit = useRef(null);
  const filesInput = useRef(null);

  const uploadMultiFiles = () => {
    setUploading(true);
    woopraHandler({ type: `${files.length} files reading...` });
    const formData = new FormData();
    [...files]?.forEach((file, index) => {
      formData.append("files", file);
    });
    axios
      .post(`${process.env.REACT_APP_FILE_API}/api/file/upload`, formData)
      .then((res) => {
        setUploading(false);
        setLoading(true);
        filePoster(res.data);
        setResFiles(res.data);
        setPermFiles((prev) => [...prev, ...files]);
        woopraHandler({
          type: `${files.length} files uploaded successfully`,
          obj: {
            fileSent: res.data.map((data) => {
              return data.filename;
            }),
          },
        });
      })
      .catch((err) => {
        setUploading(false);
        setLoading(false);
        alert("Error occured with the server. Please try again later.");
        console.log(err);
      })
      .finally(() => {
        formData.delete("files");
      });
  };

  const filePoster = (folder) => {
    const reqBody = {
      container: "api-original-content",
      key: folder[count]?.filename,
      fileName: files?.[count]?.name,
      user: window.sessionStorage.getItem("user_mail"),
      tryCognni: true,
      mode: selectedMode,
    };

    if (count > folder?.length - 1) return setLoading(false);
    axios
      .post(
        `${process.env.REACT_APP_FILE_API}/api/file/check`,
        {
          ...reqBody,
        },
        {
          headers: {
            Authorization: " Bearer aab67732-d2db-4df6-a876-197509526c18",
            "X-HMAC-SIGNATURE": computeHmacsha256(
              reqBody,
              "2318f109-70b5-46a2-92f4-3df3b0f92e5f"
            ),
          },
        }
      )
      .then((res) => {
        setCount(count + 1);
        setFilesStatus((filesStatus) => [
          ...filesStatus,
          { ...res.data, status: "success", mode: selectedMode },
        ]);
      })
      .catch((err) => {
        setCount(count + 1);
        setFilesStatus((filesStatus) => [
          ...filesStatus,
          { ...err.response, status: "error" },
        ]);
      });
  };

  useEffect(() => {
    if (files && loading) {
      filePoster(resFiles);
    }
  }, [count]);

  useEffect(() => {
    if (!loading) {
      setCount(0);
      setFile([]);
      filesInput.current.value = null;
    }
  }, [loading]);

  // Automize file upload without clicking button
  useEffect(() => {
    if (files?.length > 0 && !loading) {
      submit.current.click();
    }
  }, [files]);

  return (
    <UploadButtonStyle
      opacity={loading || uploading ? 0.5 : 1}
      onSubmit={handleSubmit}
      className={className}
      onClick={() => woopraHandler({ type: "upload_files_button_click" })}
    >
      <div id="file-label" htmlFor="file">
        <input
          disabled={loading || uploading}
          type="file"
          name="file"
          id="file"
          accept=".docx,.xlsx,.txt,.pdf"
          multiple
          ref={filesInput}
          onChange={async (e) => {
            const { files } = e.target;
            setFile([]);
            if (files.length > 20) {
              alert("You can only upload 20 files at a time");
              e.target.value = "";
              return;
            }
            // Do something with the files'
            setFile(files);
          }}
        />
        <img className="file-logo" src="/upload.svg" alt="upload-icon" />
        <p>UPLOAD FILES</p>
      </div>
      <button ref={submit} id="file-button" disabled={loading} type="submit">
        Scan {files?.length} Files
      </button>
    </UploadButtonStyle>
  );
}

export default UploadButton;
