import "./App.css";
import Header from "./Components/Header";
import Hero from "./Components/Hero";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./Components/SignInButton";
import woopraHandler from "./woopra";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

function App() {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && graphData && !sessionStorage.getItem("user_mail")) {
      // Authenticate user by woopra idenfiyer

      const user_mail = graphData.mail;
      woopraHandler({
        type: "user_authenticated",
        obj: { ...graphData },
        email: user_mail,
      });
      sessionStorage.setItem("user_mail", user_mail);
    }
  }, [graphData]);

  return (
    <div className="App">
      {!isAuthenticated ? (
        <div className="button_wrapper bg-[#fff] max-w-[1256px] m-[0_auto] rounded-[4px] min-h-[100vh]">
          <SignInButton />
        </div>
      ) : (
        <div className="bg-[#fff] max-w-[1256px] m-[0_auto] rounded-[4px] min-h-[100vh]">
          <Header />
          <main>
            <Hero />
          </main>
        </div>
      )}
    </div>
  );
}

export default App;
