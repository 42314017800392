import React from "react";
import styled from "styled-components";

const FileGridMobileBoxWrapper = styled.div`
  background: ${(props) => (props.background ? props.background : "#fff")};
  border: 1px solid #d5e9f4;
  padding: 1.8rem 1.6rem;
  .file-box {
    display: grid;
    gap: 1.6rem 1rem;
    grid-template-columns: 1fr;
    position: relative;

    .delete-icon {
      cursor: pointer;
      max-width: 18px;
      position: absolute;
      right: 0px;
    }

    &-title {
      color: var(--dark, #293748);
      font-family: Poppins;
      font-size: 16.35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-column {
      display: grid;
      gap: 10px;

      grid-template-columns: 1fr 1fr;
      grid-template-rows: 0fr;
      &-title {
        color: #000;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 18px;
        opacity: 0.7;
      }
      &-value {
        color: #000;
        font-size: 1.6rem;
        line-height: 19px;
        opacity: 0.7;
        word-break: break-word;
      }
    }
  }
`;

function FileGridMobileBox({
  name,
  type,
  topic,
  loading,
  status,
  data,
  deleteItem,
  lastModified,
  fileKey,
}) {
  return status === "success" ? (
    <FileGridMobileBoxWrapper>
      <div className="file-box">
        <div className="file-box-column first-column">
          <p className="file-box-column-value file-box-title">{name}</p>
        </div>
        <div className="file-box-column">
          <p className="file-box-column-title">Content Type:</p>
          <p className="file-box-column-value">{type || "test"}</p>
        </div>
        <div className="file-box-column">
          <p className="file-box-column-title">Algorithm:</p>
          <p className="file-box-column-value">{type || "test"}</p>
        </div>
        {/* <div className='file-box-column'>
          <p className='file-box-column-title'>Topic</p>
          <p className='file-box-column-value'>{topic}</p>
        </div> */}
        <img
          className="delete-icon"
          src="/cross.svg"
          alt="cross-icon"
          onClick={() => deleteItem(fileKey, lastModified)}
        />
      </div>
    </FileGridMobileBoxWrapper>
  ) : (
    <FileGridMobileBoxWrapper background="#FF9494">
      <div className="file-box">
        <div className="file-box-column first-column">
          <p className="file-box-column-title">Name</p>
          <p className="file-box-column-value">{name}</p>
        </div>
        <div className="file-box-column">
          <p className="file-box-column-title">Status</p>
          <p className="file-box-column-value">{data}</p>
        </div>
        <img
          className="delete-icon"
          src="/trash-icon.svg"
          alt="trash-icon"
          onClick={() => deleteItem(fileKey, lastModified)}
        />
      </div>
    </FileGridMobileBoxWrapper>
  );
}

export default FileGridMobileBox;
