import React, { useState } from "react";
import styled from "styled-components";
import FileGrid from "./FileGrid";
import UploadButton from "./UploadButton";
import QUERY from "../mediaQueries";
import woopraHandler from "../woopra";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const HeroWrapper = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1338px;
  padding: 41.5px 46px 0 46px;
  @media ${QUERY.desktop} {
    padding: 0 5rem 1.5rem 5rem;
  }

  .section-2 {
  }

  .algo-select {
    display: grid;
    align-items: center;
    background: #fff;
    position: relative;
    width: 282px;
    min-height: 56px;
    border-radius: 5.7px;
    color: #000;
    font-family: Poppins;
    font-size: 14.338px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5.729px;
    border: 1.91px solid #000;
    box-shadow: 0px 2.546px 2.546px 0px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    .selected {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .arrow {
      font-size: 12px;
    }

    .options {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      margin-top: 10px;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;

      border-top: none;
      background-color: #fff;
      z-index: 10;
    }

    .option {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;
      }
    }
    @media ${QUERY.desktop} {
      color: #000;
      font-family: Poppins;
      font-size: 24.83px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      min-height: 79px;
      text-align: center;
      margin-top: 10px;
      border-radius: 9px;
      border: 3px solid #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      width: 334px;
      .selected {
        justify-content: center;
        gap: 10px;
      }
    }
  }

  a {
    color: #2196f3;
  }

  .hero {
    &-header {
      display: grid;
      justify-content: center;
      justify-items: center;
    }
    &-logo {
      max-width: 56px;
      width: 100%;
    }
    &-title {
      &-1 {
        color: var(--dark, #293748);
        font-family: Poppins;
        font-size: 17.492px;
        font-style: normal;
        font-weight: 300;
        line-height: 168%; /* 29.387px */
        letter-spacing: 1.749px;
      }
      &-2 {
        margin-top: 21px;
        color: var(--dark, #293748);
        font-family: Poppins;
        font-size: 24.829px;
        font-style: normal;
        font-weight: 600;
        line-height: 168%; /* 41.714px */
      }
      &-3 {
        color: var(--dark, #293748);
        font-family: Poppins;
        font-size: 17.492px;
        font-style: normal;
        font-weight: 300;
        line-height: 168%; /* 29.387px */
        letter-spacing: 1.749px;
        text-align: left;
        justify-self: start;
        margin-top: 40px;
      }
    }

    &-button {
      margin-top: 14.35px;
    }
    @media ${QUERY.desktop} {
      &-header {
      }
      &-logo {
      }
      &-title {
        &-1 {
          color: var(--dark, #293748);
          text-align: center;
          font-family: Poppins;
          font-size: 23.05px;
          font-style: normal;
          font-weight: 300;
          line-height: 168%; /* 38.724px */
          letter-spacing: 2.766px;
          text-transform: capitalize;
        }
        &-2 {
          text-align: center;
          max-width: 645px;
          color: var(--dark, #293748);
          font-family: Poppins;
          font-size: 36.05px;
          font-style: normal;
          font-weight: 500;
          line-height: 168%;
        }

        &-3 {
          justify-self: center;
          color: var(--dark, #293748);
          text-align: center;
          font-family: Poppins;
          font-size: 23.05px;
          font-style: normal;
          font-weight: 300;
          line-height: 168%; /* 38.724px */
          letter-spacing: 2.766px;
          text-transform: capitalize;
        }
      }
      &-subtitle {
      }
      &-button {
      }
    }
  }
`;

function Hero() {
  // Result will be passed to filesStatus
  const [filesStatus, setFilesStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [algo, setAlgo] = useState("");
  // Temp files will be passed to files during loading
  const [files, setFile] = useState(null);
  const [permFiles, setPermFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedMode, setSelectedMode] = useState("Fast");

  const [isOpen, setIsOpen] = useState(false);
  const options = [
    "Fast",
    "Fast, Smart & Expensive",
    "Very Smart, yet Slow",
    "Very Smart, Expensive & Slow",
    "Super Smart, Expensive & Slow",
    "Many Small Brains",
  ];

  const handleOptionClick = (option) => {
    setSelectedMode(option);
    setIsOpen(false);
  };

  const deleteItem = (key, lastModified) => {
    const newFiles = filesStatus.filter((item) => item.labelId !== key);
    const newPermFiles = permFiles.filter(
      (item) => item.lastModified !== lastModified
    );

    setFilesStatus(newFiles);
    setPermFiles(newPermFiles);
    woopraHandler({ type: "item_deleted", data: { key, lastModified } });
  };

  return (
    <HeroWrapper>
      <div className="hero-header">
        <p className="hero-title-1">Want to see something really amazing?</p>
        <p className="hero-title-2">
          Upload <b>Business documents</b> to test our information recognition
        </p>
        <img className="mt-[45px]" src="/icons_list.svg" alt="" />
        <p className="hero-title-3">Reasoning Algorithm</p>
        <div className="section-2">
          <div className="algo-select">
            <div className="selected" onClick={() => setIsOpen(!isOpen)}>
              {selectedMode} <span className="arrow">{isOpen ? "▲" : "▼"}</span>
            </div>
            {isOpen && (
              <div className="options">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="option"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <UploadButton
            setLoading={setLoading}
            setFilesStatus={setFilesStatus}
            className="hero-button"
            loading={loading}
            files={files}
            setFile={setFile}
            count={count}
            setCount={setCount}
            setUploading={setUploading}
            uploading={uploading}
            setPermFiles={setPermFiles}
            selectedMode={selectedMode}
          />
        </div>
        <FileGrid
          count={count}
          loading={loading}
          files={files}
          filesStatus={filesStatus}
          permFiles={permFiles}
          uploading={uploading}
          deleteItem={deleteItem}
        />
      </div>
    </HeroWrapper>
  );
}

export default Hero;
