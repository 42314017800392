import React from "react";
import styled from "styled-components";
import QUERY from "../mediaQueries";
import FileGridMobileBox from "./FileGridMobileBox";

const FileGridWrapperWeb = styled.div`
  display: none;
  justify-items: center;
  margin-bottom: 5rem;
  /* gap: 1.2rem; */
  margin-top: 5.8rem;
  @media ${QUERY.desktop} {
    display: grid;
    width: 100%;
    .delete-icon {
      display: block;

      right: 0px;
      max-width: 20px;
      align-self: center;
      cursor: pointer;
    }
  }
`;

const FileGridWrapperMobile = styled.div`
  display: grid;
  gap: 1.3rem;
  margin-top: 2.9rem;
  @media ${QUERY.desktop} {
    display: none;
  }
`;

const Grid = styled.div`
  /* border: ${(props) =>
    props.color && !props.header ? "1px solid #c5e1f2" : "unset"}; */
  align-items: flex-start;
  background: ${(props) => props.color || "unset"};
  border-bottom: ${(props) =>
    props?.noBorder ? "0" : "1px solid var(--lighter-blue, #dee6f1)"};
  border-radius: 0px;
  border-top: 0px;
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1338px;
  padding: ${(props) =>
    props.color ? "2rem 6rem 2rem 3.8rem" : "0rem 3.8rem"};
  position: relative;
  width: 1070px;
  /* margin-bottom: ${(props) => (props.header ? "0rem" : "0.5rem")}; */
`;
const Title = styled.p`
  color: var(--dark, #293748);
  font-family: Poppins;
  font-size: 15.038px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled.p`
  color: var(--dark, #293748);
  font-family: Poppins;
  font-size: 21.83px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 14px 0;
`;

const Loading = styled.div`
  /* background: #8080804a; */
  /* border: ${(props) => (props.color ? "1px solid #c5e1f2" : "none")}; */
  /* border-radius: ${(props) => (props.color ? "8px" : "none")}; */
  display: grid;
  grid-template-columns: 1fr;
  height: 70px;
  place-items: center;
  width: 100%;
  width: 1070px;
`;

function FileGrid({
  filesStatus,
  loading,
  files,
  count,
  permFiles,
  uploading,
  deleteItem,
}) {
  return (
    <>
      <FileGridWrapperMobile>
        <>
          {filesStatus.map((file, index) => (
            <FileGridMobileBox
              key={index}
              name={permFiles[index]?.name}
              lastModified={permFiles[index]?.lastModified}
              fileKey={file?.objectId}
              type={file?.label_name}
              topic={file?.classification?.topic}
              status={file?.status}
              data={file?.data}
              loading={loading}
              deleteItem={deleteItem}
            />
          ))}
        </>
        {loading && (
          <Loading>
            <strong>{files[count]?.name}</strong> Reviewing and thinking...{" "}
            {count + 1} / {files?.length}
          </Loading>
        )}
        {uploading && <Loading>Reading files, please wait...</Loading>}
      </FileGridWrapperMobile>

      <FileGridWrapperWeb>
        {filesStatus?.length > 0 && (
          <Grid noBorder>
            <Title>File Name</Title>
            <Title>Algorithm</Title>
            <Title>Content Type</Title>
            {/* <Title>Topic</Title> */}
          </Grid>
        )}
        {filesStatus.map((file, index) =>
          file.status === "success" ? (
            <Grid key={index}>
              <Text>{permFiles[index]?.name}</Text>
              <Text>{file?.mode}</Text>
              <Text
                style={{
                  opacity: file?.inScope ? 1 : 0.7,
                }}
              >
                {file?.labelName?.replaceAll("_", " ")}
                <div className="text-[10px] text-gray-500 ml-[1px] font-[400]">
                  {file?.language}
                </div>
              </Text>
              {/* <Text>{file?.classification?.topic}</Text> */}
              {/* <img
                className="delete-icon"
                src="/trash-icon.svg"
                alt="trash-icon"
                onClick={() =>
                  deleteItem(file?.labelId, permFiles[index]?.lastModified)
                }
              /> */}
            </Grid>
          ) : (
            <Grid color="#FF9494" key={index}>
              <Text>{file?.data}</Text>
              <img
                className="delete-icon"
                src="/trash-icon.svg"
                alt="trash-icon"
                onClick={() =>
                  deleteItem(file?.labelId, permFiles[index]?.lastModified)
                }
              />
            </Grid>
          )
        )}
        {loading && (
          <Loading color="#000">
            <strong>{files[count]?.name}</strong> Reviewing and thinking...{" "}
            {count + 1} / {files?.length}
          </Loading>
        )}
        {uploading && (
          <Loading color="#000">Reading files, please wait...</Loading>
        )}
      </FileGridWrapperWeb>
    </>
  );
}

export default FileGrid;
