import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Header from "./Header";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <div className="auth_button_wrapper">
      <Header />
      <p className="init-title-1">Want to see something really amazing?</p>
      <p className="init-title-2">
        Get hands-on experience with our information recognition
      </p>
      <p className="init-title-1 ">but first please</p>

      <button
        className="auth_button"
        as="button"
        onClick={() => handleLogin("redirect")}
      >
        <p>SIGNIN WITH</p>
        <img src="./Microsoft.svg" alt="" />
      </button>
    </div>
  );
};
