import React from "react";
import styled from "styled-components";
import QUERY from "../mediaQueries";

const HeaderWrapper = styled.header`
  display: grid;
  justify-content: center;
  padding: 46px 0px 0px 46px;
  .header {
    &-logo {
      max-width: 127px;
      width: 100%;
    }
  }
  @media ${QUERY.desktop} {
    padding: 5rem 5rem 0 5rem;
    margin-bottom: 50px;
    .header {
      &-logo {
        max-width: 167px;
      }
    }
  }
`;

function Header() {
  return (
    <HeaderWrapper>
      <img className="header-logo" src="/cognni-logo.png" alt="logo" />
    </HeaderWrapper>
  );
}

export default Header;
